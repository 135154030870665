<template>
    <div class="field-container">
        <!-- Label -->
        <div
            v-if="label"
            class="w-full field-label"
            :class="{
                'field-label--disabled': disabled,
                'field-label--error': false,
            }"
        >
            <span>{{ label }}</span> <span v-if="required">*</span>
        </div>

        <!-- Input -->
        <div
            class="field-input-container"
            :class="{
                'field-input-container--error': error,
            }"
        >
            <NumberFieldRoot class="w-full text-sm text-neutral-darker" v-model:modelValue="valueRef" :min="minValue" :max="maxValue">
                <div class="flex h-full items-center justify-between">
                    <NumberFieldDecrement
                        class="flex items-center shrink-0 justify-center text-neutral-darker disabled:opacity-20 w-8 border-r border-neutral-normal-light"
                    >
                        <span>-</span>
                    </NumberFieldDecrement>
                    <NumberFieldInput class="text-neutral-darker w-16 shrink bg-transparent text-center grow-0">{{
                        valueRef
                    }}</NumberFieldInput>
                    <NumberFieldIncrement
                        class="flex items-center shrink-0 justify-center text-neutral-darker disabled:opacity-20 w-8 border-l border-neutral-normal-light"
                    >
                        <span>+</span>
                    </NumberFieldIncrement>
                </div>
            </NumberFieldRoot>
        </div>
    </div>
</template>

<script setup>
import { useVModel } from '@vueuse/core';

import { NumberFieldDecrement, NumberFieldIncrement, NumberFieldInput, NumberFieldRoot } from 'radix-vue';
import '../../../css/field.css';

const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    modelValue: {
        type: Number,
        required: true,
    },
    minValue: {
        type: Number,
        default: 0,
    },
    maxValue: {
        type: Number,
        default: 100,
    },
    error: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:modelValue']);

const valueRef = useVModel(props, 'modelValue', emit);

console.log('', props);
console.log('', props);
</script>

<style lang="postcss" scoped>
.field-input-container {
    @apply p-0;
}
.field-input-container--error {
    border: 2px solid red;
}
</style>
